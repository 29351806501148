@import "../node_modules/bulma/css/bulma.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/animate.css/animate.min.css";


:root {
    --hla-primary: #a00000;
    --hla-secondary: #2a2a2a;
    --bulma-family-primary: "Century Gothic", sans-serif;
    --bulma-link-text: var(--hla-primary);
    --bulma-scheme-main-bis: var(--hla-secondary);
    --bulma-link-h: 0;
    --bulma-link-s: 100%;
    --bulma-link-l: 31%;
}

html,
body {
    height: 100vh;
}

#header.container {
    flex-grow: unset;
}

.media {
    a {
        transition: all .3s;

        &:hover {
            filter: brightness(1.5);
        }
    }
}

.area-effect {
    cursor: pointer;
    margin: 0.75rem;
    padding: 1.5rem;
    border: 1px solid var(--hla-primary);
    border-radius: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05);

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
        transform: translateY(-5px);
    }

    &:active {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
        transform: translateY(0);
    }
}

.team {
    .card {
        font-size: 0.9rem;

        .card-image {
            animation: fadeIn 2s;

            figure {
                transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                filter: grayscale(1);

                &:hover {
                    // Slight red tint on hover
                    filter: grayscale(1) sepia(.3) hue-rotate(-50deg) saturate(1);
                    transform: scale(1.01);
                }
            }
        }
    }

    a {
        color: var(--hla-secondary);
    }
}

.resume {
    border-top: 2px solid whitesmoke;

    .member-name {
        color: var(--hla-primary);
    }

    .member-title {
        font-style: italic;
        font-weight: 600;
        color: gray;
    }

    .member-foreign-language {
        color: var(--hla-secondary);
    }

    .member-social-media {
        i {
            color: #616161;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
                transform: scale(1.2);
                color: var(--hla-primary);
            }
        }
    }

    ul {
        list-style: none;

        li::before {
            content: "\2022";
            color: var(--hla-primary);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }

    .card-image {
        figure {
            background-color: var(--hla-primary);
            animation: fadeIn 2s;
            filter: grayscale(1);

            img {
                transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

                &:hover {
                    opacity: 0.9;
                    transform: scale(1.01);
                }
            }
        }
    }
}

.navbar {
    .navbar-brand {
        .navbar-item {
            background-color: unset;

            img {
                max-height: unset;
                height: 45px;
                margin: 15px;
            }
        }
    }

    .navbar-menu {
        margin: 1rem 0;

        .navbar-item {
            &.is-active {
                background-color: var(--hla-primary);
                color: whitesmoke;
            }

            &:hover {
                background-color: var(--hla-primary);
                color: whitesmoke;
            }

            &:not(:last-child):after {
                content: "";
                height: 2rem;
                position: absolute;
                left: 100%;
                border-right: 1px solid lightgray;
            }

            &.lang,
            &.theme-icon {
                background-color: unset;

                :hover {
                    color: var(--hla-secondary);
                }
            }
        }
    }
}

.page-image {
    max-height: 25rem;
    width: 100vw;
    object-fit: cover;
    animation: fadeIn 1s ease-out;
}

.footer {
    color: lightgray;

    a {
        transition: all .3s;

        &:hover {
            filter: brightness(1.5);
        }
    }

    img {
        margin-bottom: 1rem;
        filter: brightness(0.5);
        transition: filter 0.3s;

        &:hover {
            filter: brightness(.9);
        }
    }
}
